<template>
  <div class="modal skin-modal offer-modal">
    <div class="overlay"  @click="$emit('closeSaleSkinModal')"></div>
    <div class="wrapper">
      <img class="close" @click="$emit('closeSaleSkinModal')" src="./../assets/close.svg"/>
      <div class="container">
        <form>
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="title">Sell Your Skin</div>
              <div class="form-fields-wrapper">
                <div class="input-container">
                  <div class="desc">Skin name:</div>
                  <input type="text" placeholder="Skin name" v-model="title"/>
                </div>
                <div class="input-container">
                  <div class="desc">Desired price:</div>
                  <input type="text" placeholder="Desired price" v-model="price"/>
                </div>
                <div class="input-container">
                  <div class="desc">Quality:</div>
                  <select v-model="quality">
                    <option v-for="(item, i) in qualityOptions" :value="item" :key="i">{{item}}</option>
                  </select>
                </div>
                <div class="input-container">
                  <div class="desc">Steam inventory link to skin:</div>
                  <input type="text" placeholder="Steam inventory link to skin" v-model="item_url"/>
                </div>
                <div class="button" @click="create()">
                  <span>Send</span>
                </div>
                <transition name="fade">
                  <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
                </transition>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SaleSkinModal',
  props: [],
  components: {
  },
  data: function() {
    return {
      title: '',
      price: '',
      quality: '',
      item_url: '',
      qualityOptions: [
        'Well-Worn',
        'Field-Tested',
        'Minimal Wear',
        'Factory New',
      ],
    }
  },
  methods: {
    closeSaleSkinModal() {
      this.$emit('closeSaleSkinModal')
    },
    create() {
      let data = {
        'title': this.title,
        'price': this.price,
        'quality': this.quality,
        'item_url': this.item_url
      };
      this.$emit('createOffer', data)
    }
  }
}
</script>