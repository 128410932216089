<template>
  <div class="modal reg-modal page-inside">
    <div class="main-wrapper">
      <section class="section hero-section">
        <div class="wrapper">
          <div class="container">
            <div class="form">
              <div class="form-wrapper">
                <div class='form-fields register-content'>
                  <div class="title">
                    Sign Up
                  </div>
                  <div class="form-fields-wrapper">
                    <label>
                      <div class="desc">*First name</div>
                      <input type="text" placeholder="First name" v-model="name"/>
                    </label>
                    <label>
                      <div class="desc">*Last Name</div>
                      <input type="text" placeholder="Last Name" v-model="surname"/>
                    </label>
                    <label>
                      <div class="desc">*Email</div>
                      <input type="email" placeholder="Email" v-model="email"/>
                    </label>
                    <label>
                      <div class="desc">*Phone</div>
                      <input type="tel" placeholder="Phone" v-model="phone"/>
                    </label>
                    <label>
                      <div class="desc">*Password</div>
                      <input type="password" placeholder="Password" v-model="pass"/>
                    </label>
                    <label>
                      <div class="desc">*Confirm Password</div>
                      <input type="password" placeholder="Confirm Password" v-model="passConfirm"/>
                    </label>
                    <div class="cta-container">
                      <div class="chekbox-container">
                        <label class="chekbox-label">
                          <div class="chekbox-label-wrapper">
                            <input type="checkbox" name="terms" v-model="terms"/>
                            <div class="checkbox"></div>
                            <span class="title">I agree with </span>
                            <a @click="$parent.goToPage('privacy')" class="title"> privacy policy</a>
                            <span class="title"> and </span>
                            <a @click="$parent.goToPage('terms')" class="title"> terms and conditions</a>
                          </div>
                        </label>
                      </div>
                      <button :class="['button', {'disabled': !requiredRegisterFieldsAreFilled}]" @click="submitRegister">
                        <span>Register now</span>
                      </button>
                    </div>
                    <transition name="slide">
                      <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
                    </transition>
                    <div class="modal-bottom">
                      <div class="desc">Have account?</div>
                      <div class="link switch-login" @click="$parent.openSignIn">Login now</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Registration',
  props: [],
  components: {
  },
  data: function() {
    return {
      email: '',
      pass: '',
      name: '',
      surname: '',
      phone: '',
      passConfirm: '',
      terms: false
    }
  },
  mounted() {
    
  },
  computed: {
    requiredRegisterFieldsAreFilled() {
      let fieldsAreFilled;
      if(
        this.name && this.surname  && this.phone && 
        this.email && this.pass && this.passConfirm
        && this.terms 
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  methods: {
    submitRegister() {
      let regData = {
        "name": this.name,
        "surname": this.surname,
        "email": this.email,
        "phone": this.phone,
        "password": this.pass,
        "passConfirm": this.passConfirm
      }
        this.$emit('registration', regData)
    }
  }
}
</script>