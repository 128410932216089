<template>
	<header class="header">
        <div class="wrapper">
          <div class="left">
            <router-link to="/" class="logo" exact>
              <img src="./../assets/logo.svg" class="img"/>
              <img src="./../assets/logo-mini.svg" class="mob img"/>
            </router-link>
            <ul class="nav">
              <template v-for="(item, i) in $parent.nav">
                <li class="nav__item" :key="i">
                  <router-link :to="item.link" exact>{{item.title}}</router-link>
                </li>
              </template>
            </ul>
          </div>
          <div class="right">
            <div 
              v-if="curr"
              class="select-container" 
            >
              <Select2 
                v-model="curr"
                class="select2 single currency-select" 
                :placeholder="curr"
                :options="$parent.currencyOptions"
                @select="selectCurr($event)"
              />
            </div>
            <router-link to="/cart" v-if="$parent.isAuth" :class="['icon', {'gray': !cartContents.length}]">
              <svg xmlns="http://www.w3.org/2000/svg" width="23.667" height="23" viewBox="0 0 23.667 23">
                <path id="grocery-store" d="M1,1A1,1,0,0,0,1,3H2.779a.694.694,0,0,1,.658.474l3.3,9.893a2.438,2.438,0,0,1,.053,1.361l-.271,1.088A2.585,2.585,0,0,0,9,19H21a1,1,0,0,0,0-2H9a.54.54,0,0,1-.545-.7l.2-.818A.641.641,0,0,1,9.281,15H20a1,1,0,0,0,.949-.684l2.666-8A1,1,0,0,0,22.666,5H6.555A.694.694,0,0,1,5.9,4.526L4.949,1.684A1,1,0,0,0,4,1ZM8,20a2,2,0,1,0,2,2A2,2,0,0,0,8,20Zm12,0a2,2,0,1,0,2,2A2,2,0,0,0,20,20Z" transform="translate(0 -1)"/>
              </svg>
              <div v-if="cartContents.length" class="indicator">
                {{cartContents.length}}
              </div>
            </router-link>
            <router-link to="/profile" class="button link-container dropdown-open" v-if="$parent.isAuth">
              <img src="./../assets/user.svg" class="img"/>
            </router-link>
            <div class="link-container" v-else>
              <div class="button modal-open" @click="openSignIn">
                <span>Sign In</span>
              </div>
            </div>
          </div>
        </div>
    </header>
</template>

<script>
import Select2 from 'vue3-select2-component';

export default {
	name: 'Header',
  components: {
    Select2
  },
  props: {
    cartContents: {
      type: [Array, String],
      required: true
    },
    currencyCode: {
      type: String,
      required: true
    },
  },
	data: function() {
		return {
      curr: '',
		}
	},
  watch: {
    currencyCode(newValue) {
      this.curr = newValue;
    },
  },
	methods: {
    openSignIn() {
      this.$router.push({path: '/login'});
    },
    openSignUp() {
      this.$router.push({path: '/registration'});
    },
    openSaleSkinModal() {
      this.$emit('openSaleSkinModal')
    },
    logout() {
      this.$emit('logout')
    },
    selectCurr(event) {
      this.curr = event.text;
      this.changeCurrency(event.text)
      localStorage.setItem("currency", event.text);
    },
    changeCurrency(item) {
      this.$emit('changeCurrency', item)
    },
	},
  mounted() {
    this.curr = this.currencyCode
  },
}
</script>
