<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper home">
          <div class="title big">You can always get New Counter-Strike 2 Skins here</div>
          <div class="img-container">
            <img src="./../assets/hero.png" class="img"/>
          </div>
        </div>
      </div>
      <div class="section category-section" v-if="$parent.categoryOptions.length">
        <div class="wrapper">
          <slick ref="slick" :options="slickOptions" class="slider list" @swipe="handleSwipe">
            <div class="item" v-for="item in $parent.categoryOptions" :key="item">
              <div class="item-wrapper" @click="$parent.setProductCategory(item)">
                <img :src="getImagePath(item)" class="img"/>
                <div class="desc">{{item}}</div>
              </div>
            </div>
          </slick>
        </div>
      </div>
      <div class="section arrivals-section sale-section">
        <div class="wrapper">
          <div class="top">
            <div class="title">
              Discount Store
            </div>
            <router-link to="/sale" class="link">
              <span>See all</span>
            </router-link>
          </div>
          <SlickItem v-if="$parent.saleProducts.length" :list="$parent.saleProducts" :tag="'Sale'" :addToCartChosenItem="addToCartChosenItem" @addToCart="addToCart" @goToProduct="goToProduct" :currency="currency"/>
        </div>
      </div>
      <div class="section more-offers-section">
        <div class="wrapper">
          <div class="title">New arrivals Counter-Strike 2 Skins here</div>
          <router-link to="product-list/all" class="button">Get started</router-link>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import SlickItem from '../components/SlickItem.vue';
import Slick from 'vue-slick';

export default {
  name: 'Home',
  props: ['addToCartChosenItem', 'currency'],
  components: {
    SlickItem,
    Slick
  },
  data: function() {
    return {
      imgDomain: '',
      dailyOffer: {},
      sliderIsClickble: true,
      slickOptions: {
        slidesToShow: 8,
        slidesToScroll: 5,
        arrows: false,
        infinite: false,
        dots: false,
        responsive: [
          {
            breakpoint: 1240,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 4,
            }
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            }
          },
          {
            breakpoint: 560,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          }
        ]
      },
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.getDailyOffer();
  },
  methods: {
    getImagePath(item) {
      const cleanItem = item.replace(/\s+/g, '');
      return require(`@/assets/types/${cleanItem.toLowerCase()}.svg`);
    },
    getDailyOffer() {
      this.$http.get(process.env.VUE_APP_API + 'items/daily')
      .then((res) => {
        this.dailyOffer = res.data.payload;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
      })
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    addToCart(item, id) {
      this.$emit('addToCart', item, id)
    },
    handleSwipe() {
      let self = this;
      self.sliderIsClickble = false;
      setTimeout(()=> {
        self.sliderIsClickble = true;
      }, 300)
    },
  }
}
</script>