<template>
  <main class="main cart-page page-inside" v-if="$parent.isAuth">
    <div class="main-wrapper">
      <section class="section hero-section">
        <div class="wrapper">
          <div class="title small">
            Cart
          </div>
          <div class="left">
            <div class="product-list">
              <div class="item" v-for="(item, i) in $parent.cartContents" :key="i">
                <div class="preview">
                  <img :src="imgDomain + item.item.img_url" class="img"/>
                </div>
                <div class="title">{{item.item.title}}</div>
                <div>
                  <div class="count">
                    <div class="count__item minus" @click="$parent.removeOneFromCart(item, item.item_id)"></div>
                    <input type="number" value="1" v-model="item.count"/>
                    <div class="count__item plus" @click="$parent.addToCart(item.item, item.item_id)"></div>
                  </div>
                  <div class="price">{{item.item.price}} <span class="currency">{{$parent.currency}}</span></div>
                </div>
                <div class="delete" @click="$parent.removeFromCart(item.item, item.item_id)">
                  <div class="desc">Delete item</div>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="wrapper">
              <div class="title small">Contact information</div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>First name:</div>
                <input type="text" placeholder="First name" v-model="name"/>
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>Last name:</div>
                <input type="text" placeholder="Last name" v-model="surname"/>
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>Phone:</div>
                <input type="tel" placeholder="Phone" v-model="phone"/>
              </div>
              <div class="input-container steam-input-container">
                <div class="desc"><span class="red">*</span>Steam ID:</div>
                <input type="text" placeholder="Steam ID" v-model="steamId"/>
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>Country:</div>
                <Select2 class="select2 single" v-model="country" 
                :options="countryOptions" 
                @select="selectCountry($event)"
                placeholder="Country"
                 />
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>City:</div>
                <input type="text" placeholder="City" v-model="city"/>
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>Address:</div>
                <input type="text" placeholder="Address" v-model="address"/>
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>Post Code:</div>
                <input type="text" placeholder="Post Code" v-model="postCode"/>
              </div>
              <div class="input-container">
                <div class="chekbox-container">
                  <label class="chekbox-label">
                    <div class="chekbox-label-wrapper">
                      <input type="checkbox" name="terms" v-model="terms"/>
                      <div class="checkbox"></div>
                      <span class="title">I agree with </span>
                      <a @click="$parent.goToPage('privacy')" class="title">privacy policy </a>
                      <span class="title"> and </span>
                      <a @click="$parent.goToPage('terms')" class="title">terms and conditions</a>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div class="wrapper">
              <div class="row">
                <div class="desc">Total to pay:</div>
                <div class="title">{{$parent.totalSum}} <span class="currency">{{$parent.currency}}</span></div>
              </div>
              <transition name="fade">
                <div v-if="error" class="error-desc desc red">{{error}}</div>
              </transition>
              <div class="button-container">
                <button @click="submit('card')" :class="['button', {'disabled': !requiredFieldsAreFilled}]">
                  <span>Credit / Debit cards</span>
                </button>
              </div>
              <button @click="submit('apple')" :class="['button apple-button', {'disabled': !requiredFieldsAreFilled}]">
                <img src="./../assets/apple-pay.svg" class="img"/>
              </button>
              <button @click="submit('google')" :class="['button google-button', {'disabled': !requiredFieldsAreFilled}]">
                <img src="./../assets/g.png" class="img"/>
              </button>
              <button @click="submit('blik')" :class="['button blik-button', {'disabled': !requiredFieldsAreFilled}]" v-if="$parent.currencyCode == 'PLN'">
                <img src="./../assets/blik.svg" class="img"/>
              </button>
              <div class="title small">Minimal order amount is 5 EUR</div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import Select2 from 'vue3-select2-component';

export default {
  name: 'Cart',
  props: [],
  components: {
    Select2
  },
  data: function() {
    return {
      imgDomain: '',
      countryOptions: [],
      countryOptionsId: [],
      name: '',
      surname: '',
      phone: '',
      steamId: '',
      country: '',
      countryId: '',
      city: '',
      address: '',
      postCode: '',
      terms: false,
      error: ''
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.getProfile();
    this.getCounties();
  },
  computed: {
    requiredFieldsAreFilled() {
      let fieldsAreFilled;
      if(
        this.name && this.surname  && this.phone && 
        this.steamId && this.country && 
        this.city && this.address  
        && this.postCode && this.terms 
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  methods: {
    selectCountry(event) {
      this.country = event.text;
      for (let i = 0; i < this.countryOptions.length; i++) {
        if (this.countryOptions[i] == event.text) {
          this.countryId = this.countryOptionsId[i];
        }
      }
    },
    submit(type) {
      let data = {
        "name": this.name,
        "surname": this.surname,
        "phone": this.phone,
        "steamId": this.steamId,
        "country": this.countryId,
        "city": this.city,
        "address": this.address,
        "postCode": this.postCode,
        "paymentType": type
      }
      this.$http.post(process.env.VUE_APP_API + 'orders/checkout', data)
      .then((res) => {
          window.location = res.data.redirect_url;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        } else {
          this.error = res.response.data.message;
        }
      })
    },
    getProfile() {
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
          this.name = res.data.name;
          this.surname = res.data.surname;
          this.phone = res.data.phone;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
      })
    },
    getCounties() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
      .then((res) => {
          let arr = res.data.payload;
          let newCountries = arr.map(function(item) {
            return item['title'];
          });
          let newCountriesId = arr.map(function(item) {
            return item['id'];
          });
          this.countryOptionsId = newCountriesId;
          this.countryOptions = newCountries;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
      })
    }
  }
}
</script>