<template>
	<div class="menu">
		<ul class="nav">
			<template v-for="(item, i) in $parent.nav">
			<li class="nav__item" :key="i" v-if="item.link != '/privacy' && item.link != '/terms'">
				<router-link :to="item.link" exact>
				<span v-if="item.title == 'New Arrivals'">New</span> 
				<template v-if="item.title == 'New Arrivals'">
					Arrivals
				</template>
				<template v-else>
					{{item.title != 'Sale'? item.title : ''}}
				</template>
				<span class="red" v-if="item.title == 'Sale'">Sale</span>
				</router-link>
			</li>
			</template>
			<!-- <div class="nav__item" v-if="$route.name == 'Profile'  || $route.name == 'SellOffers'">
			<a @click="$emit('openSaleSkinModal')">Sale your skin</a>
			</div> -->
		</ul>
		<div 
			v-if="curr"
			class="select-container" 
		>
			<Select2 
			v-model="curr"
			class="select2 single currency-select" 
			:placeholder="curr"
			:options="$parent.currencyOptions"
			@select="selectCurr($event)"
			/>
		</div>
	</div>
</template>

<script>
import Select2 from 'vue3-select2-component';
/*import Vue from 'vue';
var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)*/
export default {
	name: 'Menu',
	components: {
		Select2
	},
	props: {
		currencyCode: {
			type: String,
			required: true
		},
	},
	data: function() {
		return {
			
			curr: '',
		}
	},
	watch: {
		currencyCode(newValue) {
		this.curr = newValue;
		},
	},
	methods: {
		selectCurr(event) {
			this.curr = event.text;
			this.changeCurrency(event.text)
			localStorage.setItem("currency", event.text);
		},
		changeCurrency(item) {
			this.$emit('changeCurrency', item)
		},
	},
	mounted() {
		this.curr = this.currencyCode
	},
}
</script>
