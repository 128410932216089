var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer"},[_c('div',{staticClass:"footer-top-section"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"center"},[_c('ul',{staticClass:"nav"},[_vm._l((_vm.$parent.nav),function(item,i){return [_c('li',{key:i,staticClass:"nav__item"},[_c('router-link',{attrs:{"to":item.link,"exact":""}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)]})],2),_c('ul',{staticClass:"nav"},[_vm._l((_vm.$parent.textPageList),function(item){return [_c('li',{key:item.id,staticClass:"nav__item"},[_c('a',{on:{"click":function($event){return _vm.$parent.goToPage(item.id)}}},[_vm._v(" "+_vm._s(item.title)+" ")])])]})],2)])])]),_c('div',{staticClass:"footer-section"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"left"},[(_vm.$parent.footerRequisites)?_c('div',{staticClass:"text"},[_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.$parent.footerRequisites))])]):_vm._e()]),_vm._m(0),_c('div',{staticClass:"right"},[_c('ul',{staticClass:"list"},[(_vm.$parent.footerSupportEmail)?_c('li',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v("Support:")]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.$parent.footerSupportEmail))])]):_vm._e()])])])]),_c('div',{staticClass:"copyright-section"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"left"},[_c('ul',{staticClass:"list"},[_vm._m(1),_vm._m(2),_vm._m(3),(_vm.$parent.currencyCode == 'PLN')?_c('li',{staticClass:"item"},[_c('img',{staticClass:"img blik",attrs:{"src":require("./../assets/blik.svg")}})]):_vm._e(),_vm._m(4),_vm._m(5)])]),_c('div',{staticClass:"right"},[(_vm.$parent.footerCopyright)?_c('div',{staticClass:"desc"},[_vm._v("© "+_vm._s((new Date()).getFullYear())+" "+_vm._s(_vm.$parent.footerCopyright))]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"center"},[_c('a',{staticClass:"logo",attrs:{"href":"index.html"}},[_c('img',{staticClass:"img",attrs:{"src":require("./../assets/logo.svg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"item"},[_c('img',{staticClass:"img",attrs:{"src":require("./../assets/mc_symbol.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"item"},[_c('img',{staticClass:"img",attrs:{"src":require("./../assets/visa.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"item"},[_c('img',{staticClass:"img",attrs:{"src":require("./../assets/3ds.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"item"},[_c('img',{staticClass:"img",attrs:{"src":require("./../assets/google-pay-primary-logo-logo-svgrepo-com.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"item"},[_c('img',{staticClass:"img apple-pay",attrs:{"src":require("./../assets/apple-pay.svg")}})])
}]

export { render, staticRenderFns }