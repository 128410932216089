<template>
  <div class="modal offer-modal">
    <div class="overlay"  @click="$emit('closeOfferDetailsModal')"></div>
    <div class="wrapper">
      <img class="close" @click="$emit('closeOfferDetailsModal')" src="./../assets/close.svg"/>
      <div class="container">
        <form>
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="title small">Order #{{$parent.orderToView.id}}</div>
              <div class="form-fields-wrapper">
                <div class="offer-wrapper">
                  <div class="row">
                    <div class="title">Skin name</div>
                    <div class="desc">{{$parent.orderToView.title}}</div>
                  </div>
                  <div class="row">
                    <div class="title">Quality</div>
                    <div class="desc">{{$parent.orderToView.quality}}</div>
                  </div>
                  <!-- <div class="row">
                    <div class="title">Item count</div>
                    <div class="desc">{{$parent.orderToView.count}}</div>
                  </div> -->
                  <div class="row">
                    <div class="title">Your price</div>
                    <div class="desc">{{$parent.orderToView.user_price}} {{$parent.orderToView.currency}}</div>
                  </div>
                  <div class="row">
                    <div class="title">Our price</div>
                    <div class="desc">{{$parent.orderToView.service_price}} {{$parent.orderToView.currency}}</div>
                  </div>
                  <div class="buttons">
                    <div class="button" @click="decline()">Decline</div>
                    <div class="button" @click="accept()">Accept</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OfferDetailsModal',
  props: ['orderToView'],
  components: {
  },
  data: function() {
    return {
      
    }
  },
  methods: {
    closeOfferDetailsModal() {
      this.$emit('closeOfferDetailsModal')
    },
    decline() {
      let data = {
        'id': this.orderToView.id
      }
      this.$http.get(process.env.VUE_APP_API + 'offer/decline', data)
      .then(() => {
        this.closeOfferDetailsModal();
      })
    },
    accept() {
      this.$parent.openAcceptOfferModal();
    }
  }
}
</script>